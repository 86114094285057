<template>
  <div v-if="type" class="mt-5">
    <div class="d-flex">
      <back-square url="RequestTypes" />

      <div>
        <h5 class="title font-weight-bold">{{ type.name }}</h5>
        <p class="grey--text">{{ type.description }}</p>
      </div>

      <v-spacer></v-spacer>


      <div class="d-flex gap-5">
        <v-btn
          @click="$refs.typeDialog.dialog = true"
          color="primary"
          small
        >
          Add Type
        </v-btn>

        <v-btn
          @click="editType()"
          color="primary"
          outlined
          small
        >
          Edit Category
        </v-btn>

        <v-btn @click="confirmDeleteType()" color="red" small icon>
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>

    <preloader v-if="status.gettingProducts" />

    <v-alert type="info" v-if="!status.gettingProducts && !products.length" border="left" text>
      No product found.
    </v-alert>

    <div class="d-flex flex-wrap gap-10" v-if="!status.gettingProducts && products.length">
      <type-card 
        v-for="product in orderBy(products, 'name', 'asc')"
        :key="product.id"
        :type="product"
        product
      />
    </div>

    <!-- CREATE PRODUCT -->
    <create-product
      ref="typeDialog"
      :type="type"
    />

    <!-- DELETE TYPE -->
    <confirm-delete
      :show="deleteTypeDialog"
      :deleting="status.deleting"
      message="Are you sure you want to delete this request type?"
      textConfirmation="Request"
      captcha
      @cancel="closeDeleteTypeDialog()"
      @confirmed="deleteTypeConfirmed()"
    />
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import { mapState, mapActions } from 'vuex'

import TypeCard from './components/TypeCard'
import CreateProduct from './components/CreateProductDialog'

export default {
  metaInfo: {
    title: 'Request Type'
  },

  /*------------------------------------------------------------------------------
   * DATA ITEMS
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      toDelete: null,
      createDialog: false,
      deleteMessage: null,
      deleteTypeDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      products: state => state.types.products,
      status: state => state.types.status,
    }),

    type: function () {
      let id = this.$route.params.id
      return this.$store.getters['types/getType'](id)
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'type': {
      handler(type) {
        if (Object.keys(type).length) this.getProducts(type)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    TypeCard,
    CreateProduct,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'getProducts',
      'getType',
      'deleteType',
    ]),

    closeDialog() {
      this.createDialog = false
      this.$store.commit('types/resetProduct')
    },

    editType() {
      if (this.type) {
        this.$store.commit('types/setData', this.type)
        this.$parent.showDialog()
      }
    },

    showAddProductDialog() {
      this.createDialog = true
    },

    confirmDeleteType() {
      this.deleteTypeDialog = true
    },

    closeDeleteTypeDialog() {
      this.deleteTypeDialog = false
    },
    
    deleteTypeConfirmed() {
      Promise.all([
        this.deleteType(this.type)
      ])
      .then(() => {
        this.closeDeleteTypeDialog()
        this.$router.push({ name: 'RequestTypes' })
      })
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getType(this.$route.params.id)
  }
}
</script>