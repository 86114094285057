<template>
  <v-sheet 
    :max-width="small ? '100%' : '295'" 
    width="100%" 
    v-if="type"
    :class="{ 'isGrey' : grey }"
  >
    <v-hover v-if="noneRoute" v-slot="{ hover }">
      <v-card
        @click="$emit('selected')"
        :style="{ transition: 'all 0.3s' }"
        :class="{ 'box-shadow': hover }"
        class="rounded-lg"
        outlined
      >
        <v-card-text class="pa-7 text-center">
          <type-image :type="type" :hover="hover" />

          <h4 class="mb-0 subtitle-1" :style="{ lineHeight: '1.1' }">{{ type.name }}</h4>
          <!-- <p style="color: rgba(19,45,61,0.5)" class="mb-0 caption">{{ type.description }}</p> -->
        </v-card-text>
      </v-card>
    </v-hover>
    <v-hover v-else v-slot="{ hover }">
      <v-card
        :to="!product && !action ? { name: 'RequestType', params: { id: type.id } } : { name: 'ProductFormBuilder', params: { id: $route.params.id, product: type.id } }"
        :style="{ transition: 'all 0.3s' }"
        :class="{ 'box-shadow': hover }"
        outlined
      >
        <v-card-text class="pa-7 text-center">
          <type-image :type="type" />

          <h4 class="mb-0 subtitle-1" :style="{ lineHeight: '1.1' }">{{ type.name }}</h4>
          <p style="color: rgba(19,45,61,0.5)" class="mb-0 caption">{{ type.description }}</p>
        </v-card-text>
      </v-card>
    </v-hover>
  </v-sheet>
</template>

<script>
import TypeImage from './TypeImage'

export default {
  props: {
    type: Object,
    grey: Boolean,
    small: Boolean,
    action: Boolean,
    product: Boolean,
    noneRoute: Boolean,
  },

  components: {
    TypeImage,
  }
}
</script>

<style lang="scss">

.isGrey {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.5;
}

</style>