<template>
  <v-dialog
    :max-width="$dialog.content"
    v-model="dialog"
    scrollable 
  >
    <close-btn @click="close()" overflow />
    
    <v-card>
      <v-card-title class="mb-2">
        <h5 class="font-weight-bold text-h5">
          {{ product.id ? 'Update Request Type' : 'Create Request Type' }}
        </h5>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="status.error" transition="fade" type="error" border="left" text>
          {{ status.error }}
        </v-alert>

        <v-form ref="productForm" @submit.prevent="validateForm()">
          <v-text-field
            :rules="[rules.required]"
            v-model="product.name"
            label="Name"
            outlined
          ></v-text-field>
          
          <v-select
            :rules="[rules.required]"
            :items="categories"
            v-model="category"
            label="Category"
            item-text="name"
            return-object
            outlined
          ></v-select>

          <v-btn 
            :loading="status.creatingProduct"
            @click="validateForm()"
            color="primary"
            class="mr-2"
            small
          >Create</v-btn>
          
          <v-btn 
            :disabled="status.creatingProduct"
            @click="close()"
            small
            text
          >Cancel</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      dialog: false,
      category: null,
      deleteImg: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.types.status,
      product: state => state.types.product,
      categories: state => state.types.types,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'status.uploading': function (val) {
      if (!val) {
        this.close()
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'createProduct',
      'updateProduct',
      'deleteTypeImage'
    ]),

    show() {
      this.dialog = true
    },

    close() {
      this.dialog = false

      setTimeout(function () {
        this.$refs.productForm.reset()
        this.$refs.productForm.resetValidation()
      }, 1000)
    },

    validateForm() {
      if (this.$refs.productForm.validate()) {
        Promise.resolve(this.createProduct(this.category))
        .then(
          (product) => {
            this.$router.push({ name: 'ProductFormBuilder', params: { id: this.category.id, product: product.id } })
            this.close()
          },
          (error) => {
            console.log(error)
          }
        )
      }
    },

    deleteImage() {
      this.deleteImg = true
    },

    closeDeleteImage() {
      this.deleteImg = false
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteTypeImage(this.product)
      ])
      .then(() => {
        this.closeDeleteImage()
      })
    }
  }
}
</script>